.basics {

    .border-top {
        border-left: 2px var(--border-color) solid;
        border-right: 2px var(--border-color) solid;
        border-top: 2px var(--border-color) solid;

        &:before {
            content: "F";
            font-size: 0;
            width: 2px;
            height: 200%;
            background-color: var(--border-color);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -200%;
        }

        &.high:before {
            height: 500%;
            top:  -500%;
        }
    }

    .border-bottom {
        border-left: 2px var(--border-color) solid;
        border-right: 2px var(--border-color) solid;
        border-bottom: 2px var(--border-color) solid;

        &:before {
            content: "F";
            font-size: 0;
            width: 2px;
            height: 300%;
            background-color: var(--border-color);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 24px;
        }

        &.high:before {
            height: 600%;
        }
    }


    .basics-layouts {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .stats-explanation {
        margin-top: 175px;
        margin-bottom: 195px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .stat-image {
            overflow-y: hidden;
            height: 58px;
            img {   
                height: 96px;
            }
        }

        .stats {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            justify-items: flex-start;
            color: #000000;
            width: 544px;
            font-weight: bold;
        }

        .stats-top {
            & > div {
                height: 24px;
                position: relative;
            }

            .block {
                position: absolute;
                top: -80px;
                left: 50%;
                transform: translateX(-50%);
                white-space: nowrap;
                color: #ffffff;
            }
        }

        .stats-top div:nth-child(even){
            .block{
                top: -150px;
            }
        }

        .stats-bottom {
            & > div {
                height: 24px;
                position: relative;
            }

            .block {
                position: absolute;
                top: 80px;
                left: 50%;
                transform: translateX(-50%);
                white-space: nowrap;
                color: #ffffff;
            }
        }

        .stats-bottom div:nth-child(even){
            .block{
                top: 150px;
            }
        }

        .stats-bottom{
            align-items: flex-start;
        }

        .stat-pmeter {
            margin-left: 4px;
            width: 140px;
        }

        .stat-airmeter {
            width: 108px;
            margin-left: 9px;
        }

        .stat-levelstars {
            width: 68px;
            margin-left: 21px;
        }

        .stat-daymeter {
            width: 64px;
            margin-left: 11px;
        }

        .stat-badge {
            width: 55px;
            margin-left: 6px;
        }

        .stat-exp {
            margin-left: 4px;
            width: 140px;
        }

        .stat-coins {
            width: 108px;
            margin-left: 9px;
        }

        .stat-totalstars {
            width: 68px;
            margin-left: 21px;
        }

        .stat-cherries {
            width: 64px;
            margin-left: 9px;
        }

        .stat-item {
            width: 55px;
            margin-left: 64px;
        }
    }

    .stats-descriptions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 15px;
        padding: 15px;

        .stat-description {
            border: 2px outset #c0c0c0;
            overflow: hidden;
            box-shadow: 5px 5px 6px #0000007a;

            h1 {
                color: #ffffff;
                font-size: 16px;
                text-align: center;
                padding: 4px;
                margin: 0;
                text-transform: uppercase;
            }
        }

        .stat-description-text {
            padding: 10px;
        }
    }

    .basics-screenshots {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 25px;

        img { 
            margin: 0 15px;
        }
    }

    .right-arrow {
        width: 112px;
        height: 56px;
    }

    .basics-table {
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        .box-layout {
            display: flex;
            flex-direction: row;

            img {
                width: 64px;
                height: 64px;
                margin-right: 15px;
            }
        }
    }

    @media screen and (max-width: ${mobile}) {
        .stats-descriptions {
            grid-template-columns: 1fr;
        }

        .basics-screenshots {
            flex-direction: column;
        }

        .right-arrow {
            transform: rotate(90deg);
        }
    }
}