@font-face {
  font-family: new super mario font u;
  font-style: normal;
  font-weight: 400;

  src: local("New Super Mario Font U"),
    url("https://fonts.cdnfonts.com/s/32226/New Super Mario Font U.woff")
      format("woff");
}
@font-face {
  font-family: KabelHeavy;
  font-style: normal;
  font-weight: 400;

  src: local("OPTIKabel-Heavy"),
    url("/public/fonts/OPTIKabel-Heavy.woff") format("woff");
}
@font-face {
  font-family: "BabyTeeth";
  font-style: normal;
  font-weight: 400;
  src: local("Baby Teeth"), url("/public/fonts/Devandra.ttf") format("truetype");
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  background-image: url("./images/background.svg");
  background-position: center;
}
body {
  background-color: #080810;
  border: 2px solid #000000;
  border-width: 0 2px;
  color: #e0e0e0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0 auto;
  max-width: 9.5in;
  overflow-x: hidden;
  padding-bottom: 50px;
  position: relative;

  .content {
    padding: 25px;
  }
}

h1 {
  border-bottom: 2px solid #808080;
  font-family: KabelHeavy;
  font-size: 48px;
  margin-bottom: 25px;
  padding-bottom: 25px;
  text-align: center;
  line-height: 50px;
  width: 100%;
}

h2 {
  font-family: "Righteous", sans-serif;
  font-size: 36px;
  margin-bottom: 15px;
}
ul, ol {
  margin: 15px;
  padding-left: 15px;
}

a:link,
a:visited,
a:hover,
a:active {
  color: #c0c0ff;
}

section {
  display: block;
  margin-bottom: 50px;
}

.social-media {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  right: 0;

  img {
    border: 0;
    margin: 0 10px;
    width: 28px;

    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(125deg)
      brightness(103%) contrast(103%);
  }
}
.main-header {
  .block {
    width: 100%;
  }
}

img {
  border: 2px outset #c0c0c0;
}

@media screen and (max-width: 1023px) {
  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .social-media {
    flex-direction: row;
  }
}
.corner-block.block {
  height: 32px;
  left: -32px;
  position: absolute;
  top: -32px;
  width: 32px;
}


.logo_title {
  background-color: #64B0FF;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  padding: 10px;

  img {
    max-width: calc(100% - 25px);
    border: 0;
  }
}