.about {
    .about-screenshots {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 25px;
    }

    img {
        max-width: 100%;
    }
}