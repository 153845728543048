.home {
  align-items: center;
  display: flex;
  flex-direction: column;

  p{
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: #bff0ff;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .home-description {
    margin: 50px auto;
  }
  .title-3 {
    max-width: 200px;
  }
  .home-table-contents {
    width: 95%;
  }

  .home-table-contents {
    border: 2px outset #c0c0c0;
    font-family: KabelHeavy;
    margin-top: 25px;
    padding: 25px;
  }
  .home-table-contents-list {
    align-items: center;
    display: flex;
    font-size: 36px;
    font-weight: bold;
    line-height: 40px;
    margin-top: 25px;
    white-space: nowrap;
    width: 100%;

    div:nth-child(2) {
      border-top: 4px dotted #e0e0e0;
      height: 2px;
      margin: 5px;
      margin-top: 10px;
      overflow: hidden;
      width: 100%;
    }
  }
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #e0e0e0;
    text-decoration: none;
  }
  @media screen and (max-width: 1023px) {
    .home-table-contents-list {
      font-size: 24px;
    }
  }
}
