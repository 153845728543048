.nes-pad {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    border: 1px solid black;
    height: 75px;
    margin: 0 10px;
    width: 225px;

    background-color: #101018;
    border-radius: 2px;
}