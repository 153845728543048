.techniques {
    .techniques-screenshots {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 25px;

        img { 
            margin: 0 15px;
        }
    }

    .right-arrow {
        border: 0;
        width: 112px;
        height: 56px;
    }

    @media screen and (max-width: 1023px){
        .techniques-screenshots {
            flex-direction: column;
        }

        .right-arrow {
            transform: rotate(90deg);
        }
    }
}