.page-title {
      align-items: start;
      display: flex;
      justify-content: space-between;
      white-space: nowrap;

  .page-description {
        align-self: center;
        margin-right: -10px;
        padding: 15px;
        width: 45%;
    }
  .block {
        box-shadow: none;
        text-align: center;

    .block-layout  {
            padding: 0;
        }
    }
  .page-title {
        -webkit-text-stroke: 4px #000000;
        clear: both;
        display: inline-block;
        font-family: "New Super Mario Font U", sans-serif;
        font-size: 72px;
        line-height: 80px;
        padding: 2px;
        padding-left: 8px;
        padding-top: 8px;
        text-align: center;
        text-shadow: 6px 6px 0px #000000;
        text-transform: uppercase;
        white-space: nowrap;
        word-spacing: 15px;

    .letter {
          color: var(--letter-color);
          margin-left: -8px;

      &.kern-fix {
            margin-right: 6px;
        }
      }
    }
  .break {
        display: block;
    }
  &.center {
        align-items: center;
        flex-direction: column;
        white-space: normal;
        width: 100%;

    .page-title {
          margin-left: 0px;
      }
    .page-description {
          padding: 15px;
          width: 90%;
      }
    .letter_0 {
          font-size: 72px;
      }
    }
  @media screen and (max-width: 1023px) {
        align-items: center;
        flex-direction: column;
        margin-bottom: 0;
        width: 100%;

    .page-title {
          font-size: 50px;
          line-height: 40px;
          margin-left: 0px;
      }
    .page-description {
          margin: 25px 0;
          padding: 0;
          width: 90%;
      }
    .mobile-break {
          display: block;
      }
    .block {
          width: auto;
      }
    }

    @media screen and (max-width: 400px) { 
      .page-title {
        font-size: 40px;
      }
    }
  }
