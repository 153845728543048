.power-ups {
  align-content: space-evenly;
  align-items: center;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-evenly;
  justify-items: center;
  row-gap: 25px;
  width: 100%;

  .power-up-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100% - 25px);
    justify-content: space-evenly;
    justify-items: center;
  }
  .box {
    height: 450px;
  }
  .power-up-image {
    background-color: #629bd9;
    border-radius: 32px;
    left: 0;
    padding: 8px;
    position: absolute;
    top: 0;

    img {
      border: 0;
      width: 32px;
    }
  }
  .power-up-screenshot {
    align-self: end;
    border: 2px outset #c0c0c0;
    display: inline-block;
    height: 172px;
    margin: 10px;
    max-width: 256px;
    overflow: hidden;
    width: calc(100% - 20px);

    img {
        border: 0;
      left: -40px;
      position: relative;
    }
  }
  .power-up-mario {
    align-self: center;
    margin: 8px;

    img {
      border: 0;
      height: 64px;
    }
  }
  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
    justify-items: center;

    .power-up-box {
      max-width: 400px;
      width: 100%;
    }
  }
}
