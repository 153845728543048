.level-guide {
    .level-guide-section-image {
        display: grid;
        place-items: center;
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
        width: 100%;

        img {
            border: 0;
        }

        
    }

    .level-guide-map {
        position: relative;
        width: 100%;

        &.crop {           
            height: var(--crop-height);
            overflow: hidden;
            width: var(--crop-width);

            & > img,
            .level-guide-section-annotation,
            .level-guide-tip-marker {
                transform: translate(calc(var(--crop-x)));
            }
        }
    }
    
    .level-guide-section-annotation {
        align-self: center;
        display: flex;
        height: var(--height);
        left: var(--x);
        justify-content: center;
        opacity: .9;
        position: absolute;
        top: var(--y);
        height: var(--width);

        img {
            border: 0;
        }

        label {
            align-items: center;
            border: .5px solid #000000;
            background-color: #ffffff;
            color: #000000;
            padding: 0 1px;
            display: flex;
            line-height: 14px;
            font-weight: bold;
            font-size: 12px;
            justify-content: center;
            position: absolute;
            min-width: 100%;
            white-space: nowrap;

            &.top {
                left: 50%;
                position: absolute;
                top: 0;
                transform: translate(-50%, -100%);
            } 

            &.bottom {
                left: 50%;
                position: absolute;
                bottom: -100%;
                transform: translate(-50%, -100%);
            } 
        }
    }

    .level-guide-tip-marker {
        border: 1px solid #ffffff;
        outline: 1px solid #ff0000;
        height: var(--height);
        left: var(--x);
        position: absolute;
        top: var(--y);
        width: var(--width);

        &:hover {
            background-color: #ffffff80;
            cursor: pointer;
        }
    }

    .level-guide-tip-images {
        align-items: center;
        display: flex;
        justify-content: center;
        gap: 15px;
        flex-wrap: wrap;
    }
}