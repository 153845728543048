@keyframes open-animation {
  0% {
    border-radius: 25px;
    max-height: 50px;
    max-width: 50px;
  }

  100% {
    border-radius: 10px;
    max-height: 100%;
    max-width: 100%; 
  }
}

@keyframes close-animation {
  0% {
    height: calc-size(auto);
    border-radius: 10px;
    max-height: 100%;
    max-width: 100%;
  }

  100% {
    border-radius: 25px;
    max-height: 50px;
    max-width: 50px;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.navigation {
  align-items: center;
  background-color: #404040;
  border-radius: 25px;
  bottom: 25px;
  box-shadow: 5px 5px 6px #0000007a;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: calc(100% - 50px);
  overflow: hidden;
  position: sticky;
  text-align: left;

  &.open {
    border-radius: 10px;
    width: 300px;

    .navigation-icon {
      display: none;
    }
  }

  nav {
    list-style: none;
    padding: 10px 0;
    width: 100%;
  }

  &:not(.open) {
    height: 50px;
    width: 50px;

    nav {
      display: none;
    }
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #ffffff;
    display: block;
    font-size: 16px;
    padding: 12px 12px;
    text-decoration: none;
    width: 100%;
  }

  a:hover {
    background-color: #808080;
  }

  nav > li:last-child {
    border-top: 1px solid #ffffff;
  }

  .navigation-icon {
    .navigation-icon-bar {
      background-color: white;
      border-radius: 10px;
      height: 3px;
      margin: 5px 0;
      width: 30px;
    }
  }
}
