.box
{
    border: 2px outset #c0c0c0;
    position: relative;
    width: 400px;
    max-width: 90vw;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: 5px 5px 6px #0000007a;

    h2 {
        background-color: var(--box-color);
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        padding: 4px;
        margin: 0;
        text-transform: uppercase;
    }

    .box-layout {
        padding: 10px;
        height: 100%;
    }
}