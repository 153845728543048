.save-editor {
    table {
        width: 100%;

        td:not(:nth-child(2)) {
            text-align: center;
        }
    }

    .download-button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }
}